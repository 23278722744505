import PageRoutes from "../routes/routes";
import Navbar from "./navigation/navbar";

const MainLayout = () => {
  return (
    <div className="flex flex-col h-screen bg-lightblack">
      <Navbar />
      <PageRoutes />
    </div>
  );
};

export default MainLayout;
