//package imports
import { useEffect, useState } from "react";

//local imports
import Loader from "../../components/loader/loader";
import Toast from "../../components/toast/toast";
import EmailService from "../../services/email-service";
import { MessageStatus } from "../../utils/enums/message-status";
import { ToastType } from "../../utils/enums/toast";
import { Message } from "../../utils/types/message";

//css
import "./contact.css";

const Contact = () => {
  const [message, setMessage] = useState<Message>({
    name: "",
    email: "",
    message: "",
  });
  const [messageStatus, setMessageStatus] = useState<MessageStatus>(
    MessageStatus.NotInitiated
  );

  useEffect(() => {
    if (
      messageStatus === MessageStatus.Success ||
      messageStatus === MessageStatus.Failed
    ) {
      const timer = setTimeout(() => {
        setMessageStatus(MessageStatus.NotInitiated);
      }, 3500);
      return () => clearTimeout(timer);
    }
  }, [messageStatus]);

  const handleMessageInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setMessage((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const contactMe = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessageStatus(MessageStatus.Started);

    await EmailService.sendEmailAsync(message)
      .then(() => {
        setMessageStatus(MessageStatus.Success);
      })
      .catch((err) => {
        setMessageStatus(MessageStatus.Failed);
        throw err;
      });
  };
  return (
    <div className="flex-grow overflow-y-auto p-4">
      <div className="w-full flex flex-row mt-20">
        <div className="flex flex-col justify-center px-32 w-full">
          <span className="text-white text-left text-3xl font-bold">
            Say hello!
          </span>
          <div className="flex flex-row mt-10 justify-center  w-full">
            <div className="basis-1/2">
              {" "}
              <form onSubmit={contactMe}>
                {messageStatus === MessageStatus.Started ? (
                  <Loader />
                ) : (
                  <>
                    <input
                      id="name"
                      name="name"
                      placeholder="Your name"
                      onChange={handleMessageInputChange}
                      className="form-input w-full h-10 pl-3 mb-3 rounded-md"
                    />
                    <br />
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Your email"
                      onChange={handleMessageInputChange}
                      className="form-input w-full h-10 pl-3 mb-3 rounded-md"
                    />
                    <br />
                    <textarea
                      id="message"
                      name="message"
                      placeholder="Write your message"
                      onChange={handleMessageInputChange}
                      className="form-input w-full h-20 pl-3 mb-3 rounded-md"
                    />
                    <div className="text-right">
                      <button className="bg-transparent place-items-center font-semibold py-2 px-4 border hover:border-transparent rounded text-white hover:bg-white hover:text-black">
                        Send Message
                      </button>
                    </div>
                  </>
                )}
              </form>
              {messageStatus === MessageStatus.Success ? (
                <Toast type={ToastType.success} />
              ) : null}
              {messageStatus === MessageStatus.Failed ? (
                <Toast type={ToastType.fail} />
              ) : null}
            </div>
            <div className="basis-1/2">
              <div className=" text-white">
                <div className="flex flex-row mb-5">
                  <div className="basis-1/4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 float-right"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                      />
                    </svg>
                  </div>
                  <div className="basis-3/4 pl-3">
                    <span>+94 773 269 713</span>
                  </div>
                </div>

                <div className="flex flex-row mb-5">
                  <div className="basis-1/4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 float-right"
                    >
                      <path
                        strokeLinecap="round"
                        d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
                      />
                    </svg>
                  </div>
                  <div className="basis-3/4 pl-3">
                    <span>nipun.yesh@gmail.com</span>
                  </div>
                </div>

                <div className="flex flex-row mb-5">
                  <div className="basis-1/4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 float-right"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                      />
                    </svg>
                  </div>
                  <div className="basis-3/4 pl-3">
                    <span>Weligama, Sri Lanka, 81700</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
