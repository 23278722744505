//local imports
import { SkillBarProps } from "../../utils/props/skill-bar-props";

const SkillBar = ({ percentage, name }: SkillBarProps) => {
  return (
    <>
      <span className="text-white font-bold text-xl">{name}</span>
      <div className="w-full rounded-full h-2.5 mb-4 mt-2 bg-black">
        <div
          className="h-2.5 rounded-full bg-[#34D399]"
          style={{ width: percentage }}
        ></div>
      </div>
    </>
  );
};

export default SkillBar;
