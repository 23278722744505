import { ToastType } from "../../utils/enums/toast";
import { ToastProps } from "../../utils/props/toast-props";

const Toast = ({ type }: ToastProps) => {
  return (
    <div
      className="bg-white border-t-4 rounded-b text-black px-4 py-3 shadow-md fixed top-20 right-2"
      role="alert"
    >
      {type === ToastType.success ? (
        <>
          <div className="flex">
            <div className="mr-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            </div>
            <div>
              <span className="">Got your message</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex">
            <div className="mr-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <div>
              <span className="">Something went wrong. Couldn't send the message</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Toast;
