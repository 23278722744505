import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeView from "../views/home/home";
import About from "../views/about/about";
import Portfolio from "../views/portfolio/portfolio";
import Skills from "../views/skills/skills";
import Blogs from "../views/blogs/blogs";
import Contact from "../views/contact/contact";
import Feedbacks from "../views/feedbacks/feedbacks";

const PageRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeView />} />
      <Route path="/about" element={<About />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/feedbacks" element={<Feedbacks />} />
      <Route path="/skills" element={<Skills />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
};

export default PageRoutes;
