//css
import "./about.css";

const About = () => {
  const onButtonClick = () => {
    fetch("docs/ayesh_nipun.pdf").then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "ayesh_nipun.pdf";
        alink.click();
      });
    });
  };

  const onNavigateButtonClick = () => {
    window.open("https://www.fiverr.com/ayeshnipun?public_mode=true", "_blank");
  };
  return (
  <div className="flex-grow overflow-y-auto p-4">
      <div className="w-full h-full flex flex-row">
        <div className="basis-1/5 flex justify-center place-items-center"></div>
        <div className="basis-3/5 flex justify-center place-items-center">
          <div className="flex flex-col">
            <span className="text-left p-2 text-lg text-white">
              <h1>
                🚀 Crafting Digital Wonders, One Line of Code at a Time 🖥️
              </h1>
              <br />
              As a seasoned
              <span className="text-xl font-extrabold highlight-text">
                {" "}
                Senior Software Engineer
              </span>{" "}
              with almost{" "}
              <span className="text-xl font-extrabold highlight-text">
                5 years
              </span>{" "}
              of industry experience, and{" "}
              <span className="text-xl font-extrabold highlight-text">
                Microsoft Certified Azure Developer
              </span>
              , I'm your trusted partner in bringing digital dreams to life.
              With a passion for precision and a flair for innovation, I blend
              the art of coding with the science of design to create elegant
              solutions that simply work.
            </span>
            <br />

            <div className="text-right">
              <span className="text-right p-2 text-lg text-white">
                Fluent in the language of{" "}
                <span className="text-xl font-extrabold highlight-text">
                  .NET Core
                </span>
                , adept at crafting{" "}
                <span className="text-xl font-extrabold highlight-text">
                  dynamic React applications
                </span>
                , and skilled in taming{" "}
                <span className="text-xl font-extrabold highlight-text">
                  SQL databases
                </span>
                , I thrive on turning complex problems into beautifully simple
                solutions.{" "}
                <span className="text-xl font-extrabold highlight-text">
                  Azure
                </span>{" "}
                is my playground, where I harness the power of the cloud to
                scale and secure our creations.
              </span>
            </div>
            <br />
            <br />

            <div className="text-center">
              <button
                onClick={onButtonClick}
                className="mx-2 bg-transparent place-items-center font-semibold py-2 px-4 border hover:border-transparent rounded text-white hover:bg-[#34D399] hover:text-black"
              >
                Download CV
              </button>
              <button
                onClick={onNavigateButtonClick}
                className="mx-2 bg-transparent place-items-center font-semibold py-2 px-4 border hover:border-transparent rounded text-white hover:bg-[#34D399] hover:text-black"
              >
                Connect via Fiverr
              </button>
            </div>
          </div>
        </div>
        <div className="basis-1/5 flex justify-center place-items-center"></div>
      </div>
    </div>
  );
};

export default About;
