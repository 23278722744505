const WorkStepper = () => {
  return (
    <div>
      <ol>
        <li className="border-r border-b border-white">
          <div className="flex justify-end items-center pt-3 mb-2">
            <span className="text-white text-sm">April, 2022 - Present</span>
            <div className="bg-white w-8 h-8 rounded-full -mr-4 ml-3 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 inline"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                />
              </svg>
            </div>
          </div>
          <div className="text-right mt-0.5 mr-4 mb-6">
            <div className="header mb-2">
              <span className="highlight-text font-semibold text-xl mb-1.5">
                Senior Software Engineer {" "}
                <span className="text-white font-medium text-md mb-2">
                  @ Ascentic, Sri Lanka
                </span>
              </span>
            </div>
            <span className="text-white text-sm mb-3 mt-2">
              Mainly woking with{" "}
              <span className="font-extrabold">
                C#, .NET Core, JavaScript, TypeScript, ReactJS, SQL, MSSQL, and
                Azure
              </span>{" "}
              . Also self learning and working with{" "}
              <span className="font-extrabold">
                Docker, K8s, RabbitMQ, and Terraform
              </span>
            </span>
          </div>
        </li>

        <li className="border-l border-white">
          <div className="flex justify-start items-center pt-3 mb-2">
            <div className="bg-white w-8 h-8 rounded-full -ml-4 mr-3 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 inline"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                />
              </svg>
            </div>
            <span className="text-white text-sm">
              August, 2019 - March, 2022
            </span>
          </div>
          <div className="text-left mt-0.5 ml-4 mb-6">
            <div className="header mb-2">
              <span className="highlight-text font-semibold text-xl mb-1.5">
                Software Engineer {" "}
                <span className="text-white font-medium text-md mb-2">
                  @ Ascentic, Sri Lanka
                </span>
              </span>
            </div>
            <span className="text-white text-sm mb-3 mt-2">
              Worked with{" "}
              <span className="font-extrabold">
                C#, .NET Core, JavaScript, TypeScript, ReactJS, SQL, MSSQL, and
                Azure
              </span>{" "}
              . Also started learning{" "}
              <span className="font-extrabold">Docker and K8s</span>
            </span>
          </div>
        </li>

        {/* <li className="border-r border-white">
          <div className="flex justify-end items-center pt-3 mb-2">
            <span className="text-white text-sm">
              August, 2019 - February, 2020
            </span>
            <div className="bg-white w-8 h-8 rounded-full -mr-4 ml-3 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 inline"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                />
              </svg>
            </div>
          </div>
          <div className="text-right mt-0.5 mr-4 mb-6">
            <div className="header mb-2">
              <span className="text-white font-semibold text-xl mb-1.5">
                Software Engineer Intern @{" "}
                <span className="text-white font-medium text-md mb-2">
                  Ascentic, Sri Lanka
                </span>
              </span>
            </div>
            <span className="text-white text-sm mb-3 mt-2">
              Worked with{" "}
              <span className="font-extrabold">
                C#, .NET Core, JavaScript, TypeScript, ReactJS, SQL, and MSSQL
              </span>
            </span>
          </div>
        </li> */}
      </ol>
    </div>
  );
};

export default WorkStepper;
