//package imports
import { useEffect, useState } from "react";
import ReactTextTransition, { presets } from "react-text-transition";

//css
import "./home.css";

const HomeView = () => {
  const [index, setIndex] = useState(0);
  const texts = [
    "Senior Software Engineer",
    "Microsoft Certified Azure Developer",
    "Gamer",
    "Freelancer",
    "Drone Photographer",
    "Die-hard Marvel fan",
  ];

  useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 4000);
    return () => clearTimeout(intervalId);
  }, []);

  return (
<div className="flex-grow overflow-y-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-20">
        <div className="flex justify-end items-end">
          <div className="w-2/3 md:w-full lg:w-2/3">
            <img
              alt="banner"
              src="/images/banner.png"
              className="w-full h-auto"
            />
          </div>
        </div>
        <div className="flex flex-col justify-center p-4 md:p-8">
          <span className="highlight-text p-2 rounded-md text-3xl inline-block mb-2 md:mb-4 w-auto">
            <ReactTextTransition
              springConfig={presets.gentle}
              style={{ margin: "0 0px", fontSize: "35px" }}
              inline
            >
              {texts[index % texts.length]}
            </ReactTextTransition>
          </span>
        </div>
      </div>
    </div>
  );
};

export default HomeView;
