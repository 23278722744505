import React from "react";
import FeedbackCorousel from "../../components/feedback/feedback-corousel";

const Feedbacks = () => {
  return (
    <div className="flex-grow overflow-y-auto p-4">
      <div className="w-full flex flex-row mt-20">
        <div className="flex flex-col justify-center px-32">
          <span className="text-white text-left text-3xl font-bold">
            Feedbacks and Reviews
          </span>
          <div className="mt-5 w-full">
            <FeedbackCorousel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedbacks;
