//local imports
import SkillBar from "../../components/skill-bar/skill-bar";
import EducationStepper from "../../components/steppers/education-stepper";
import WorkStepper from "../../components/steppers/work-stepper";
import { SkillBarProps } from "../../utils/props/skill-bar-props";

//css
import "./portfolio.css";

const skills: SkillBarProps[] = [
  {
    percentage: "90%",
    name: "Front-end development",
    key: 1,
  },
  {
    percentage: "90%",
    name: "Back-end development",
    key: 2,
  },
  {
    percentage: "80%",
    name: "Microsoft Azure",
    key: 3,
  },
  {
    percentage: "85%",
    name: "SQL/MSSQL",
    key: 4,
  },
  {
    percentage: "65%",
    name: "Docker & Kubernetes",
    key: 5,
  },
];

const Portfolio = () => {
  return (
    <div className="flex-grow overflow-y-auto p-4">
      <div className="w-full mb-52 flex flex-row mt-20">
        <div className="w-full flex justify-start pl-32">
          <div className="w-2/3">
            <span className="text-white text-3xl font-bold">What I do</span>

            <div className="md:mt-10 lg:mt-15">
              {skills.map((skill) => {
                return (
                  <SkillBar
                    name={skill.name}
                    percentage={skill.percentage}
                    key={skill.key}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-row ">
        <div className="basis-1/5"></div>
        <div className="basis-3/5 flex flex-col justify-end">
          <span className="text-white text-right text-3xl font-bold">
            Work history
          </span>
          <div className="mt-5">
            <WorkStepper />
          </div>
        </div>
        <div className="basis-1/5"></div>
      </div>

      <div className="w-full h-full flex flex-row mt-32">
        <div className="basis-1/5"></div>
        <div className="basis-3/5 flex flex-col justify-start">
          <span className="text-white text-left text-3xl font-bold">
            Education and Qualifications
          </span>
          <div className="mt-5">
            <EducationStepper />
          </div>
        </div>
        <div className="basis-1/5"></div>
      </div>
    </div>
  );
};

export default Portfolio;
