//package imports
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

//icons
import { FiLinkedin, FiGithub, FiYoutube } from "react-icons/fi";
import { SiMedium } from "react-icons/si";

//css
import "./styles/navbar.css";

const NavBar = () => {
  const [bgColor, setBgColor] = useState<boolean>(false);
  const [url, setUrl] = useState<string | null>(null);
  const location = useLocation();

  const changeColor = () => {
    if (window.scrollY >= 10) {
      setBgColor(true);
    } else {
      setBgColor(false);
    }
  };

  useEffect(() => {
    setUrl(location.pathname);

    if (location.pathname !== "/") {
      const section = document.querySelector(
        "#" + location.pathname.split("/")[1]
      );
      section?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [location]);

  window.addEventListener("scroll", changeColor);

  return (
    <nav
      id="navbar-default"
      className={`${
        bgColor ? "bg-black" : "bg-transparent"
      }  px-2 sm:px-4 py-2.5 sticky top-0 z-10`}
    >
      <div className="container flex flex-wrap items-center justify-between mx-auto ">
        <Link to="/" className="flex items-center">
          <span className="self-center ">
            <span className="text-xl font-bold whitespace-nowrap highlight-text">
              Ayesh{" "}
            </span>{" "}
            <span className="text-xl whitespace-nowrap text-white"> Nipun</span>
          </span>
        </Link>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm rounded-lg md:hidden hover:cursor-pointer focus:outline-none focus:ring-2"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill={bgColor ? "white" : "black"}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>

        {/* middle set */}
        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul className="flex flex-col p-4 mt-4 border md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 text-white">
            <li>
              <Link
                to="/about"
                className={url === "/about" ? "active-white" : ""}
              >
                {"< "}About{" >"}
              </Link>
            </li>
            <li>
              <Link
                to="/portfolio"
                className={url === "/portfolio" ? "active-white" : ""}
              >
                {"< "}Portfolio{" >"}
              </Link>
            </li>
            <li>
              <Link
                to="/feedbacks"
                className={url === "/feedbacks" ? "active-white" : ""}
              >
                {"< "}Feedbacks{" >"}
              </Link>
            </li>
            <li>
              <Link
                to="/skills"
                className={url === "/skills" ? "active-white" : ""}
              >
                {"< "}Skills{" >"}
              </Link>
            </li>
            <li>
              <Link
                to="/blogs"
                className={url === "/blogs" ? "active-white" : ""}
              >
                {"< "}Blogs{" >"}
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={url === "/contact" ? "active-white" : ""}
              >
                {"< "}Contact{" >"}
              </Link>
            </li>
          </ul>
        </div>

        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul className="flex flex-col p-4 mt-4 border md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 text-white">
            <li className="h-6 w-6">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/ayeshnipun/"
                className="block py-2 pl-3 pr-4 rounded md:border-0 md:p-0"
              >
                <FiLinkedin size={20} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/ayeshnipun"
                className="block py-2 pl-3 pr-4 rounded md:border-0 md:p-0"
              >
                <FiGithub size={20} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://medium.com/@nipun-yesh"
                className="block py-2 pl-3 pr-4 rounded md:border-0 md:p-0 "
              >
                <SiMedium size={20} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/@b1tWizards"
                className="block py-2 pl-3 pr-4 rounded md:border-0 md:p-0 "
              >
                <FiYoutube size={20} />
              </a>
            </li>
            {/* <li>
              <MdOutlineDarkMode
                onClick={toogleTheme}
                size={24}
                className="hover:cursor-pointer"
              />
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
