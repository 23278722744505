//icons
import { GrCertificate } from "react-icons/gr";

const EducationStepper = () => {
  return (
    <div>
      <ol>
        <li className="border-l border-b border-white">
          <div className="flex justify-start items-center pt-3 mb-2">
            <div className="bg-white w-8 h-8 rounded-full -ml-4 mr-3 text-center">
              <GrCertificate className="inline mt-1" size={20} />
            </div>
            <span className="text-white text-sm">April, 2021</span>
          </div>
          <div className="text-left mt-0.5 ml-4 mb-6">
            <div className="header mb-2">
              <span className="text-white font-semibold text-xl mb-1.5">
                Microsoft Certified Azure Developer Associate (AZ-204)
              </span>
            </div>
          </div>
        </li>

        <li className="border-r border-b border-white">
          <div className="flex justify-end items-center pt-3 mb-2">
            <span className="text-white text-sm">February, 2020</span>
            <div className="bg-white w-8 h-8 rounded-full -mr-4 ml-3 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 inline"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                />
              </svg>
            </div>
          </div>
          <div className="text-right mt-0.5 mr-4 mb-6">
            <div className="header mb-2">
              <span className="text-white font-semibold text-xl mb-1.5">
                B.Sc. in Computer Science
              </span>
            </div>
            <span className="text-white text-sm mb-3 mt-2">
              University of Colombo, Sri Lanka
            </span>
          </div>
        </li>

        <li className="border-l border-white">
          <div className="flex justify-start items-center pt-3 mb-2">
            <div className="bg-white w-8 h-8 rounded-full -ml-4 mr-3 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 inline"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
                />
              </svg>
            </div>
            <span className="text-white text-sm">August, 2016</span>
          </div>
          <div className="text-left mt-0.5 ml-4 mb-6">
            <div className="header mb-2">
              <span className="text-white font-semibold text-xl mb-1.5">
                Diploma in Information Technology (Pearson Assured)
              </span>
            </div>
            <span className="text-white text-sm mb-3 mt-2">
              ESoft Metro Campus, Sri Lanka
            </span>
          </div>
        </li>
      </ol>
    </div>
  );
};

export default EducationStepper;
