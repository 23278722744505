//package imports
import { useEffect, useState } from "react";
import BlogPost from "../../components/blog-post.tsx/blog-post";

//local imports
import { getAllMediumPosts } from "../../services/post-service";
import { Blog } from "../../utils/types/blog";

//css
import "./blogs.css";

const Blogs = () => {
  const [posts, setPosts] = useState<Blog[]>([]);
  useEffect(() => {
    getMediumPosts();
  }, []);

  const getMediumPosts = () => {
    getAllMediumPosts().then((posts: any) => setPosts(posts.items.slice(0, 6)));
  };
  return (
    <div className="flex-grow overflow-y-auto p-4">
      <div className="w-full flex flex-row mt-20">
        <div className="flex flex-col justify-center px-32">
          <span className="text-white text-left text-3xl font-bold">
            Latest publications
          </span>
          <div className="flex flex-wrap mt-10 justify-center">
            {posts.map((post) => (
              <div
                key={post.guid}
                className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-4"
              >
                <BlogPost {...post} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
