import { Feedback } from "../../utils/types/feedback";

const FeedbackContent = ({
  message,
  author,
  designation,
  platform,
}: Feedback) => {
  return (
    <div className="h-full w-full flex flex-row px-20 py-20">
      <div className="basis-1/5 flex justify-center highlight-text">
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 512 512"
          className="h-12 w-12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path>
        </svg>
      </div>
      <div className="basis-4/5 flex flex-col text-white">
        <div className="basis-2/3 flex justify-center place-items-center">
          {message}
        </div>
        <div className="basis-1/3 mt-8">
          <span>
            <span className="highlight-text">{author}</span> from {platform}
          </span>
          <br />
          <span>{designation}</span>
        </div>
      </div>
    </div>
  );
};

export default FeedbackContent;
