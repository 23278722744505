//local imports
import { Feedback } from "../../utils/types/feedback";
import FeedbackContent from "./feedback-content";

const feedbacks: Feedback[] = [
  {
    message:
      "Of all the bidders, Ayesh was the only one who communicated efficiently to discuss requirements. I requested him to always clarify uncertainties and he always did this. In the end, the work he produced was to a high quality, met requirements, and was on budget and on time. Would highly recommend to others.",
    author: "Ash N",
    designation: "Product owner",
    platform: "Freelancer",
  },
  {
    message:
      "Ayesh is an individual who always likes to go the extra mile and learn attitude. He has a learning attitude and is an excellent developer in the .Net stack. He is certified in Azure development and knows full-stack development. He always likes to share his experience and knowledge via training. Anyone interested in consulting him, I would like to recommend him.",
    author: "Kasun Rajapakse",
    designation: "DevOps Engineer | Microsoft MVP - Azure",
    platform: "LinkedIn",
  },
  {
    message:
      "Ayesh is a team player. He does his best work and makes sure it is of sufficient quality. One of my first teammates when working at Ascentic and he's been as committed from day one. He's keen on getting tasks done very in a manner that is both efficient and effective. Ayesh also shows good communication skills, making sure he understands requirements and getting doubts clarified as early as possible. He has an interest in the Microsoft tech stack & contributes his knowledge on it with his peers. Seeing Ayesh's work in both react & .NET core I can say he's a well rounded full stack developer with strengths in both frontend and backend. He is also a very fun person that you can grab a drink with and have a nice chat with :)",
    author: "Kushel Ramanayake",
    designation: "Software Engineer",
    platform: "LinkedIn",
  },
  {
    message:
      "Highly tech enthusiastic person with helpful, dedicated and positive mindset and he is easily adjustable to a given situation and his ability to tackle any problem is remarkable and always came up with efficient solutions. I highly recommend Ayesh and would love to work with him again.",
    author: "Dulan Malith",
    designation: "Software Engineer",
    platform: "LinkedIn",
  },
];

const FeedbackCorousel = () => {
  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide relative w-full"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner relative w-full overflow-hidden">
        <div className="carousel-item active float-left w-full">
          <FeedbackContent
            message={feedbacks[0].message}
            author={feedbacks[0].author}
            designation={feedbacks[0].designation}
            platform={feedbacks[0].platform}
          />
        </div>
        <div className="carousel-item float-left w-full">
          <FeedbackContent
            message={feedbacks[1].message}
            author={feedbacks[1].author}
            designation={feedbacks[1].designation}
            platform={feedbacks[1].platform}
          />
        </div>
        <div className="carousel-item float-left w-full">
          <FeedbackContent
            message={feedbacks[2].message}
            author={feedbacks[2].author}
            designation={feedbacks[2].designation}
            platform={feedbacks[2].platform}
          />
        </div>
        <div className="carousel-item float-left w-full">
          <FeedbackContent
            message={feedbacks[3].message}
            author={feedbacks[3].author}
            designation={feedbacks[3].designation}
            platform={feedbacks[3].platform}
          />
        </div>
      </div>
      <button
        className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-start p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span
          className="carousel-control-prev-icon inline-block bg-no-repeat"
          aria-hidden="true"
        ></span>
      </button>
      <button
        className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-end p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span
          className="carousel-control-next-icon inline-block bg-no-repeat"
          aria-hidden="true"
        ></span>
      </button>
    </div>
  );
};

export default FeedbackCorousel;
