//local imports
import { TechStackType } from "../../utils/types/tech-stack";

const TechStack = ({ src, name }: TechStackType) => {
  return (
    <div className="w-30 h-30 flex flex-col justify-center place-items-center mb-10 mr-10">
      <div className="basis-2/3">
        <img
          src={src}
          alt="..."
          className="shadow-lg w-16 h-1w-16 border-none "
        />
      </div>
      <div className="basis-1/3 mt-3">
        <span className=" text-white">{name}</span>
      </div>
    </div>
  );
};

export default TechStack;
