//local imports
import { Blog } from "../../utils/types/blog";

const BlogPost = ({ title, pubDate, link, author, thumbnail }: Blog) => {
  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <div className="w-56 mr-10 rounded-lg shadow-md">
      <img
        className="object-cover w-full h-48 "
        src={thumbnail}
        alt="title"
      />

      <div className="p-4 bg-white">
        <h4 className="text-xl font-semibold tracking-tight text-blue-600">
          {title}
        </h4>
        <span className="text-sm">Published on {pubDate.split(" ")[0]}</span>
        <button
          className="px-4 py-2 text-sm text-white bg-black rounded shadow"
          onClick={() => openInNewTab(link)}
        >
          Read more
        </button>
      </div>
    </div>
  );
};

export default BlogPost;
