//local imports
import TechStack from "../../components/tech-stack/tech-stack";
import { TechStackType } from "../../utils/types/tech-stack";

//css
import "./skills.css";

const techStacks: TechStackType[] = [
  {
    src: "/images/skills/netcore.png",
    name: ".NET Core",
    key: 1,
  },
  {
    src: "/images/skills/react.png",
    name: "React JS",
    key: 2,
  },
  {
    src: "/images/skills/ts.png",
    name: "TypeScript",
    key: 3,
  },
  {
    src: "/images/skills/js.png",
    name: "JavaScript",
    key: 4,
  },
  {
    src: "/images/skills/sql.png",
    name: "SQL",
    key: 5,
  },
  {
    src: "/images/skills/mssql.png",
    name: "MSSQL",
    key: 6,
  },
  {
    src: "/images/skills/postgre.png",
    name: "PostgreSQL",
    key: 7,
  },
  {
    src: "/images/skills/azure.png",
    name: "Azure",
    key: 8,
  },
  {
    src: "/images/skills/azure-devops.png",
    name: "Azure DevOps",
    key: 9,
  },
  {
    src: "/images/skills/docker.png",
    name: "Docker",
    key: 10,
  },
  {
    src: "/images/skills/k8s.png",
    name: "Kubernetes",
    key: 11,
  },
  {
    src: "/images/skills/git.png",
    name: "Git",
    key: 12,
  },
];

const Skills = () => {
  return (
    <div className="flex-grow overflow-y-auto p-4">
      <div className="w-full flex flex-row mt-20">
        <div className="flex flex-col justify-center px-32">
          <span className="text-white text-left text-3xl font-bold">
            My Main Tech Stack
          </span>
          <div className="mt-5 w-full">
            <div className="mt-16">
              <div className="flex flex-wrap">
                {techStacks.map((techStack) => {
                  return (
                    <TechStack
                      src={techStack.src}
                      key={techStack.key}
                      name={techStack.name}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
