const Loader = () => {
  return (
    <div className="flex justify-center place-items-center h-full bg-transparent">
      <div className="flex items-center justify-center ">
        <div className="w-8 h-8 border-b-2 border-white rounded-full animate-spin"></div>
      </div>
      {/* <div className="grid gap-2">
      </div> */}
    </div>
  );
};

export default Loader;
