import { send, init } from "emailjs-com";
import { Message } from "../utils/types/message";

const serviceId = "service_sv6w8wh";
const templateId = "template_bxmhy7h";
const userID = "user_xfurDErqsNWqF2NCZIAPz";

const EmailService = {
  sendEmailAsync: async (content: Message) => {
    init(userID);
    const toSend = {
      from_name: content.name,
      to_name: "Ayesh Nipun",
      from_email: content.email,
      message: content.message,
    };

    return await send(serviceId, templateId, toSend)
      .then((response) => response)
      .catch((err) => {
        throw err;
      });
  },
};

export default EmailService;
